<template>
  <div class="swimming-pools">
    <sub-banner>
      <template v-slot:text>
        <h1>Zwembaden</h1>
      </template>
    </sub-banner>
    <text-image style="margin-top: -50px">
      <template v-slot:image>
<!--        <img src="../assets/images/zwembad-in-grasveld.webp" alt="Zwembad in grasveld met houten rand"-->
<!--             style="height: 350px">-->
      </template>
      <template v-slot:title>
        <h2>Bouwkundige zwembaden</h2>
      </template>
      <template v-slot:text>
        <p>Bouwkundige baden zijn volledig aan te passen naar uw wensenpakket en kan in diverse lay-outs gerealiseerd
          worden. Uiteraard voldoen al deze baden volledig aan de huidige wet- en regelgeving.</p>
        <ul class="check-list">
          <li>De filter- & verwarminginstallaties</li>
          <li>Alle wet- en regelgeving</li>
        </ul>
      </template>
    </text-image>

    <text-image flip>
      <template v-slot:image>
<!--        <img src="../assets/images/blauw-zwembad.webp" alt="Blauw zwembad in gras gemaakt door Twelvepools"-->
<!--             style="height: 400px">-->
      </template>
      <template v-slot:title>
        <h2>Prefab zwembaden</h2>
      </template>
      <template v-slot:text>
        <p>Prefab zwembaden worden vooraf in fabrieken gemaakt en vervolgens naar de juiste locatie vervoerd. Deze baden
          zijn gemaakt van duurzame materialen zoals fiberglass of vinyl en kennen een snellere en eenvoudigere
          installatie dan bouwkundige zwembaden.</p>
        <br>
        <p>De grootte en het ontwerp van prefab zwembaden kunnen aan uw specifieke behoeften worden aangepast, maar
          kennen wel maximale maatvoeringen i.v.m. het transport.</p>
      </template>
    </text-image>

    <voordelen></voordelen>
  </div>
</template>

<script>
import TextImage from '@/components/TextImage.vue'
import SubBanner from "@/components/SubBanner";
import Voordelen from "@/components/Voordelen";

export default {
  name: "Zwembaden",
  components: {
    Voordelen,
    SubBanner,
    TextImage
  },
  created() {
    window.scrollTo(0, 0)
  },
}
</script>

<style lang="scss" scoped>

$gold: #D6AD60;

.swimming-pools {
  .check-list {
    margin: 20px 0 0;
  }

  .check-list li {
    position: relative;
    list-style-type: none;
    padding-left: 2.5rem;
    margin-bottom: 0.5rem;
  }

  .check-list li:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    width: 5px;
    height: 11px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    border-color: #00a8a8;
    transform-origin: bottom left;
    transform: rotate(45deg);
  }

}

</style>

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Portfolio from '../views/Portfolio.vue'
import Zwembaden from '../views/Zwembaden.vue'
import contact from "@/views/Contact";

function lazyLoad(view){
  return() => import(`@/views/${view}.vue`)
}

const routes = [
  {
    path: '/',
    name: 'home',
    props: true,
    component: HomeView,
    meta: {
      title: "Aircotechniek | Geniet van verfrissende comfort"
    }
  },
  {
    path: '/aircos',
    name: 'Airconditioning',
    props: true,
    component: lazyLoad('Portfolio'),
    meta: {
      title: "Airco's | Aircotechniek"
    }
  },
  {
    path: '/tesla-airco',
    name: 'pf-item1',
    props: true,
    component: lazyLoad('aircos/Tesla'),
    meta: {
      title: "Tesla airco's | Aircotechniek"
    }
  },
  {
    path: '/geplaatste-aircos',
    name: 'pf-item2',
    props: true,
    component: lazyLoad('aircos/Geplaatst'),
    meta: {
      title: "Geplaatste airco's | Aircotechniek"
    }
  },
  {
    path: '/contact',
    name: 'Contact',
    props: true,
    component: contact,
    meta: {
      title: "Contact | Aircotechniek"
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
})

export default router
